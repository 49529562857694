





















import { Component, Vue, Prop } from "vue-property-decorator";
import MainHeader from "@/components/main-header/main-header.vue";
import NavLeft from "@/components/nav-left/nav-left.vue";
import FolderList from "../../components/folder-list.vue";
@Component({
  components: {
    MainHeader,
    NavLeft,
    FolderList,
  },
})
export default class Name extends Vue {
  private data: any = [
    [
      {
        title: "症状群分析",
        type: "症状群分析",
        description:
          "统计某个证型/疾病下常见症状，挖掘辨证核心症状组合及特异性症状等。",
        img: "/analysis/定性特征.png",
        isTese: false,
      },
      {
        title: "症状特异性分析",
        type: "症状特异性分析",
        description:
          "统计某个证型/疾病下常用中药饮片，挖掘专家用药核心方及用药特异性等。",
        img: "/analysis/几何特征.png",
        isTese: true,
      },
      {},
    ],
    [
      {
        title: "用药配伍分析",
        type: "用药配伍分析",
        description:
          "统计某个证型/疾病下常见症状，挖掘辨证核心症状组合及特异性症状等。",
        img: "/analysis/纹理特征.png",
        isTese: false,
      },
      {
        title: "用药特异性分析",
        type: "用药特异性分析",
        description:
          "统计某个证型/疾病下常用中药饮片，挖掘专家用药核心方及用药特异性等。",
        img: "/analysis/舌图演变.png",
        isTese: true,
      },
      {
        title: "随症用药规律",
        type: "随症用药规律",
        description:
          "统计某个证型/疾病下常用中药饮片，挖掘专家用药核心方及用药特异性等。",
        img: "/analysis/颜色特征.png",
        isTese: false,
      },
    ],
  ];
  private goToResult(item: any) {
    this.$router.push({
      path: "/main/analysis/wajue/wajue-result",
      query: {
        type: item,
      },
    });
  }
}
